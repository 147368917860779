import * as React from "react"
import Link from "gatsby-link"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <div className={"text-center mt-5"}>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to={"/"} className={"button button-primary"}>
      Return to Home page
    </Link>
  </div>
)

export default NotFoundPage
